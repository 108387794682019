<template>
  <v-combobox
    ref="input"
    v-model="selectedItems"
    :multiple="multiple"
    :error-messages="allErrors"
    append-icon=""
    :small-chips="!!selectedItems.length"
    clearable
    class="notification-email"
    :placeholder="placeholder"
    v-bind="$attrs"
    @input.native="onInput"
    @change="onChange"
  />
</template>
<script>
/**
 * ==================================================================================
 * Notification Email
 * ==================================================================================
 **/

import { sentence, isValidEmail } from '@/utils/helpers'

export default {
  name: 'NotificationEmail',
  props: {
    value: {
      type: [String, Array],
      default: () => [],
    },

    placeholder: {
      type: String,
      default: 'Add email(s)',
    },

    errorMessages: {
      type: [String, Array],
      default: () => [],
    },

    multiple: {
      type: Boolean,
      default: true,
    },

    rules: {
      type: Array,
      default: () => [],
      validator: (value) => {
        if (!Array.isArray(value)) return false

        for (let i = 0; i < value.length; i++) {
          let fnc = value[i]
          if (typeof fnc !== 'function') return false
          let check = fnc('invalid email')
          if (typeof check !== 'boolean' && typeof check !== 'string') {
            return false
          }
        }

        return true
      },
    },
  },

  data() {
    return {
      selectedItems: [],
      invalidErrors: [],
      emailErrors: [],
      duplicateErrors: [],
    }
  },

  computed: {
    allErrors() {
      return sentence(
        [
          ...(Array.isArray(this.errorMessages)
            ? this.errorMessages
            : [this.errorMessages]),
          ...this.invalidErrors,
          ...this.parseEmailErrors(),
        ].filter((a) => !!a)
      )
    },

    hasRules() {
      return this.rules.length > 0
    },
  },

  watch: {
    value(newValue, oldValue) {
      if (newValue !== oldValue && newValue !== this.selectedItems) {
        this.init()
      }
    },
  },

  mounted() {
    this.init()
  },

  methods: {
    init() {
      this.selectedItems = this.value
      this.validate()
    },

    reset() {
      this.invalidErrors = []
      this.emailErrors = []
      this.duplicateErrors = []
    },

    async refresh() {
      await this.$refs.input.blur()
    },

    /**
     * To remove the errors on empty input
     * @param {InputEvent} e
     */
    onInput(e) {
      if (!e.value) {
        this.reset()
      }
    },

    /**
     * As v-combobox does not remove invalid values
     * manually remove it
     * @param  {Array} value
     */
    onChange(value) {
      let values = this.parseEmailValue(value)

      this.reset()
      this.validate(values)
    },

    validate(items) {
      let values = items || this.selectedItems || []

      let validEmails = []
      if (values.length) {
        for (let i = 0; i < values.length; i++) {
          let email = values[i]
          let valid = this.isValid(email, validEmails)

          if (!valid) {
            //
          } else if (typeof valid === 'string') {
            this.invalidErrors.push(valid)
          } else {
            validEmails.push(email)
          }
        }
      }

      this.selectedItems = validEmails
      this.$emit('input', validEmails)
    },

    /**
     * Parse the email passed by v-combobox as it doesn't split in by comma
     * @param  {Array|String} emails
     * @return {Array}
     */
    parseEmailValue(emails) {
      if (!emails || !emails.length) return []

      let parsedEmails = Array.isArray(emails) ? emails : [emails]
      let allEmails = []
      parsedEmails.forEach((email) => {
        if (email) {
          let newEmails = []
          if (typeof email === 'string') {
            newEmails = email.split(',').map((item) => item.trim())
          }

          newEmails = newEmails.filter((email) => !!email)
          allEmails = [...allEmails, ...newEmails]
        }
      })

      return allEmails
    },

    /**
     * Parse email with errors into a sentence
     * @return {String}
     */
    parseEmailErrors() {
      return [
        this.convertToSentence(this.emailErrors),
        this.convertToSentence(this.duplicateErrors, 'duplicate'),
      ]
    },

    /**
     * Checks value if passing specified rules
     * @param  {String}  value
     * @param  {Array}   emails
     * @return {Boolean|String}
     */
    isValid(value, emails) {
      if (this.hasRules) {
        for (var i = 0; i < this.allRules.length; i++) {
          let rule = this.allRules[i]
          if (typeof rule === 'function') {
            let valid = rule(value)
            if (!valid || typeof valid === 'string') {
              return valid
            }
          }
        }
      }

      if (this.isDuplicateEmail(value, emails)) {
        if (!this.duplicateErrors.includes(value)) {
          this.duplicateErrors.push(value)
        }
        return false
      }

      if (!isValidEmail(value)) {
        this.emailErrors.push(value)
        return false
      }

      return true
    },

    isDuplicateEmail(email, emails = []) {
      return emails.includes(email)
    },

    convertToSentence(array, error = 'invalid') {
      if (!array.length) return ''

      let singular = array.length === 1
      return `${sentence(array)} ${singular ? 'is a' : 'are'} ${error} email${
        singular ? '' : 's'
      }`
    },

    /**
     * Append current value on specified FormData
     * @param  {FormData} form
     * @return {Void}
     */
    getEmails(form) {
      const emails = this.value

      if (form && emails && emails !== 'null') {
        for (let i = 0; i < emails.length; i++) {
          form.append('notification_emails[]', emails[i])
        }
      }
    },
  },
}
</script>
<style lang="scss" scoped>
/**
 * Fix placeholder only showing on focus
 * https://github.com/vuetifyjs/vuetify/issues/11553
 *
 * NOTE: Not working!!
 */
::v-deep .notification-email:not(.v-input--is-focused).v-select--chips {
  input {
    max-height: inherit !important;
  }
}
</style>
